import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import RenderMessage from "./pages/RenderMessage";
import {
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/projects">
          <Portfolio />
        </Route>
        <Route path="/404">
          <RenderMessage
            icon={faExclamationCircle}
            title="Page Not Found"
            message="Looks like the page you requested does not exist on this site."
            slug="/"
            buttonText="Return Home"
          />
        </Route>
        <Route exact path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
